<template>
  <Content>
    <template #contentTitle>
      {{ $t("menu.product_management") }}
    </template>
    <template v-slot:contentBody="wrap">
      <a-row :gutter="[16, 16]" type="flex" justify="space-between" align="bottom">
        <a-col>
          <a-row :gutter="[16, 16]">
            <a-col>
              <a-input-group compact style="width: 300px;">
                <a-select v-model:value="selectSearchType"
                          style="width: 40%;"
                          class="selectDiv">
                  <a-select-option value="productNo"
                                   :title="$t('common.product_no')"> {{ $t("common.product_no") }}</a-select-option>
                  <a-select-option value="productName"
                                   :title="$t('warehouse.product_name')">{{ $t("warehouse.product_name") }}</a-select-option>
                  <a-select-option value="seSku"
                                   title="SESKU">SESKU</a-select-option>
                </a-select>
                <a-input style="width: 60%;"
                         v-model:value="inputSearchValue"
                         allow-clear />
              </a-input-group>
            </a-col>
            <a-col>
              <a-button type="primary" :loading="inputSearchProductLoading" @click="handleInputSearch">{{ $t('common.query') }}</a-button>
            </a-col>
            <a-col>
              <a-button type="ghost" :loading="inputSearchProductLoading" @click="handleDownloadExcel">{{$t('common.export')}}</a-button>
            </a-col>
          </a-row>
        </a-col>
        <a-col>
          <a-row :gutter="[16, 16]">
            <a-col>
              <a-button @click="hanldeShowImportModal">{{ $t('common.import') }}</a-button>
            </a-col>
            <a-col>
              <a-button type="primary" @click="handleCreateProduct()" ghost>{{ $t("warehouse.add_product") }}</a-button>
            </a-col>
          </a-row>
        </a-col>
      </a-row>
      <a-table :columns="columns"
               :data-source="productList"
               :scroll="{ x: true,y: wrap.contentHeight - 110 }"
               size="small"
               :pagination="false"
               :loading="productListLoading"
               class="mt-3">
        <template #nameAndNo>
          <div >
            {{ $t("warehouse.product_name") }}/{{ $t("common.product_no") }}
          </div>
        </template>
        <template #productInfo="{ record }">
          <a-space>
            <div class="table-list-img-common ">
              <c-image :src="record.imgSrc"></c-image>
            </div>
            <div>
              <div>{{ record.productName }}</div>
              <div>{{ record.productNo }}</div>
            </div>
          </a-space>
        </template>
        <template #packagingSize>
          <span>
            {{ $t("warehouse.product_dimensions") }}
          </span>
          <a-tooltip>
            <template #title>{{$t('warehouse.product_of_size')}}</template>
            <span class="ml-1 mr-1">
              <QuestionCircleOutlined />
            </span>
          </a-tooltip>
        </template>
        <template #packingImgUrl="{ record }">
          <div class="table-list-img-common "
                v-if="record.packingImgUrl">
            <c-image :src="record.packingImgUrl"></c-image>
          </div>
        </template>
        <template #operate="{ record }">
          <a-dropdown-button @click="handleEdit(record.key)">
            {{ $t("common.edit") }}
            <template #overlay>
              <a-menu @click="handleClickOperateMenu">
                <a-menu-item :key="1"
                              :record="record">
                  {{ $t("warehouse.create_similar_product") }}
                </a-menu-item>
                <a-menu-item :key="2"
                              :record="record">
                  {{ $t("warehouse.print_sesku_label") }}
                </a-menu-item>
                <a-menu-item :key="3"
                              :record="record">
                  {{ $t("warehouse.download_se_label") }}
                </a-menu-item>

                <a-popconfirm :title="$t('common.are_you_sure')"
                              :ok-text="$t('common.confirm')"
                              :cancel-text="$t('common.cancel')"
                              @confirm="handleDeleteProduct(record)">
                  <a-menu-item key="trigger"
                                :record="record">
                    {{ $t("common.delete") }}
                  </a-menu-item>
                </a-popconfirm>
              </a-menu>
            </template>
          </a-dropdown-button>
        </template>
        <template #transportCharacteristicsCustom="{ record }">
          <a-row :gutter=[0,3]
                  v-if="record.transportCharacteristics?.length>0">
            <a-col v-for="item in record.transportCharacteristics"
                    :key="item">
              <a-tag color="default"> {{$t($enumLangkey('transportCharacteristics',item))}}</a-tag>
            </a-col>
          </a-row>
          <span v-else>-</span>
        </template>
        <template #creationTimeCustom="{ record }">
          {{$filters.utcToCurrentTime(record.creationTime)}}
        </template>
      </a-table>
      <a-modal v-model:visible="importModal.visible"
               :title="$t('warehouse.import_products')"
               centered
               :footer="false"
               :maskClosable="false">
        <a-spin :spinning="importModal.loading">
          <a-row>
            <a-col>
              <a-upload :customRequest="handleParseFile"
                        accept=".xlsx"
                        :showUploadList="false">
                <a-button>
                  <upload-outlined></upload-outlined>
                  {{$t('warehouse.select_import_file')}}
                </a-button>
              </a-upload>
            </a-col>
            <a-col>
              <a-button type="link"
                        @click="hanldeDownloadTemplateFile">{{$t('warehouse.click_download_template')}}</a-button>
            </a-col>
          </a-row>

          <a-row justify="end"
                 class="mt-3">
            <a-col>
              <a-button @click="importModal.visible=false">{{ $t("common.close") }}</a-button>
            </a-col>
          </a-row>
        </a-spin>
      </a-modal>

      <a-modal v-model:visible="importModal.listVisible"
               :title="$t('warehouse.import_products')"
               centered
               width="1000"
               :maskClosable="false">
        <a-spin :spinning="importModal.loading">
          <a-table :columns="importColumns"
                   :data-source="importModal.list"
                   :scroll="{ x: 1000,y: 650 }"
                   size="small"
                   :pagination="false"
                   :rowKey="
                   (record, index) => {
                     return index;
                   }
                 ">

            <template #nameAndNo>
              <div>
                {{ $t("warehouse.product_name") }}/{{ $t("common.product_no") }}
              </div>
            </template>
            <template #productInfo="{ record }">
              <a-space>
                <div class="table-list-img-common " v-if="record?.imgSrc">
                  <c-image :src="record.imgSrc"></c-image>
                </div>
                <div>
                  <div>{{ record.productName }}</div>
                  <div>{{ record.productNo }}</div>
                </div>
              </a-space>
            </template>
            <template #packagingSize>
              <span>
                {{ $t("warehouse.product_dimensions") }}
              </span>
              <a-tooltip>
                <template #title>{{$t('warehouse.product_of_size')}}</template>
                <span class="ml-1 mr-1">
                  <QuestionCircleOutlined />
                </span>
              </a-tooltip>
            </template>
            <template #packingImgUrl="{ record }">
              <div class="table-list-img-common "
                   v-if="record.packingImgUrl">
                <c-image :src="record.packingImgUrl"></c-image>
              </div>
            </template>
            <template #operate="{ record }">
              <a-popover placement="left"
                         v-if="record.isNormal">
                <template #content>
                  <div style="max-width:1000px">
                    <a-descriptions :title="$t('public.more')">
                    </a-descriptions>

                    <a-row>
                      <a-col>
                        <a-row>
                          <a-col>
                            <div class="table-list-img-common ">
                              <c-image :src="record.more.imgUrl"></c-image>
                            </div>
                          </a-col>
                          <a-col>
                            <div class="table-list-img-common ml-3">
                              <c-image :src="record.more.packingImgUrl"></c-image>
                            </div>
                          </a-col>
                        </a-row>
                      </a-col>
                      <a-col>
                        <div class="ml-3">
                          {{$t('warehouse.product_name')}}: {{record.more.productName}}
                          <div>
                            {{$t('warehouse.product_sku')}}: {{record.more.productNo}}
                          </div>
                        </div>
                      </a-col>
                    </a-row>
                    <a-descriptions class="mt-3"
                                    :column='5'>
                      <a-descriptions-item :label="$t('warehouse.encasement_specification')">
                        <span v-if="record.more.containerLength&&record.more.containerWidth&&record.more.containerHeight">
                          {{record.more.containerLength}}x{{record.more.containerWidth}}x{{record.more.containerHeight}} cm
                        </span>
                      </a-descriptions-item>
                      <a-descriptions-item :label="$t('warehouse.encasement_count')">
                        {{record.more.containerCount}}
                      </a-descriptions-item>
                      <a-descriptions-item :label="$t('warehouse.encasement_weight')">
                        <span v-if="gToKg(record.more.containerWeight)">
                          {{gToKg(record.more.containerWeight)}} kg
                        </span>
                      </a-descriptions-item>
                      <a-descriptions-item :label="$t('warehouse.product_dimensions')">
                        <span v-if="record.more.packingLength&&record.more.packingWidth&&record.more.packingHeight">
                          {{record.more.packingLength}}x{{record.more.packingWidth}}x{{record.more.packingHeight}} cm
                        </span>
                      </a-descriptions-item>
                      <a-descriptions-item :label="$t('warehouse.product_gross_weight')">
                        <span v-if="gToKg(record.more.packingWeight)">
                          {{gToKg(record.more.packingWeight)}} kg
                        </span>
                      </a-descriptions-item>
                      <a-descriptions-item :label="$t('warehouse.transport_characteristics')"
                                           :span="3">
                        <a-row :gutter=[0,3]
                               v-if="record.more?.transportCharacteristics?.length>0">
                          <a-col v-for="item in record.more.transportCharacteristics"
                                 :key="item">
                            <a-tag color="default"> {{$t($enumLangkey('transportCharacteristics',item))}}</a-tag>
                          </a-col>
                        </a-row>
                      </a-descriptions-item>
                      <a-descriptions-item :label="$t('warehouse.battery_type')">
                        {{ $t($enumLangkey('batteryType', record.more.batteryType)) }}
                      </a-descriptions-item>
                      <a-descriptions-item :label="$t('warehouse.battery_ingredient')">
                        {{$t($enumLangkey('batteryIngredient', record.more.batteryIngredient))}}
                      </a-descriptions-item>
                      <a-descriptions-item :label="$t('warehouse.battery_capacity')+'(mA)'">
                        {{record.more.batteryCapacity}}
                      </a-descriptions-item>
                      <template v-for="(item, index) in record.more?.pareseCustomsDeclarations"
                                :key="index">
                        <template v-if="item.isDefault">
                          <a-descriptions-item :label="$t('warehouse.currency_code')">
                            {{item?.currencyCode}}
                          </a-descriptions-item>
                          <a-descriptions-item :label="$t('warehouse.declared_price')">
                            {{item?.declarePrice}}
                          </a-descriptions-item>
                          <a-descriptions-item :label="$t('warehouse.chinese_texture')">
                            {{item?.cnMaterial}}
                          </a-descriptions-item>
                          <a-descriptions-item :label="$t('warehouse.english_texture')">
                            {{item?.enMaterial}}
                          </a-descriptions-item>
                          <a-descriptions-item :label="$t('warehouse.chinese_trade_name')">
                            {{item?.customsCnName}}
                          </a-descriptions-item>
                          <a-descriptions-item :label="$t('warehouse.english_trade_name')">
                            {{item?.customsEnName}}
                          </a-descriptions-item>
                          <a-descriptions-item :label="$t('warehouse.is_a_brand')">
                            <span v-if="item?.hasBrand">
                              {{$t('warehouse.yes')}}
                            </span>
                            <span v-else>
                              {{$t('warehouse.no')}}
                            </span>
                          </a-descriptions-item>
                          <a-descriptions-item :label="$t('warehouse.brand_name')">
                            {{item?.brand}}
                          </a-descriptions-item>
                          <a-descriptions-item :label="$t('warehouse.purpose_chinese_description')">
                            {{item?.purposeCnDescription}}
                          </a-descriptions-item>
                          <a-descriptions-item :label="$t('warehouse.purpose_english_description')"
                                               :span="5">
                            {{item?.purposeEnDescription}}
                          </a-descriptions-item>
                        </template>
                      </template>

                    </a-descriptions>
                    <!-- 显示美国品名和墨西哥品名 -->
                    <a-row v-for="(item, index) in record.more?.pareseCustomsDeclarations"
                           :key="index">
                      <template v-if="!item.isDefault">
                        <!-- 美国 -->
                        <a-col :span="24"
                               v-if="item.countryId === '231'">
                          <a-row>
                            <a-col :span="2">
                              <strong>{{$t('warehouse.american')}}</strong>
                            </a-col>
                            <a-col :span="7">
                              {{$t('warehouse.chinese_trade_name')}}: {{item?.customsCnName}}
                            </a-col>
                            <a-col :span="8">
                              {{$t('warehouse.english_trade_name')}}: {{item?.customsEnName}}
                            </a-col>
                            <a-col :span="4">
                              {{$t('warehouse.declare_price')}}: {{ $filters.formatCurrencyAmount(item?.currencyCode, item?.declarePrice, false) }}
                            </a-col>
                            <a-col :span="3">
                              {{$t('warehouse.customs_no')}}: {{item?.hsCode}}
                            </a-col>
                          </a-row>

                        </a-col>
                        <!-- 墨西哥 -->
                        <a-col :span="24"
                               v-if="item.countryId === '142'"
                               class="mt-3">
                          <a-row type="flex"
                                 justify="space-between">
                            <a-col :span="2">
                              <strong> {{$t('warehouse.mexico')}}</strong>
                            </a-col>
                            <a-col :span="7">
                              {{$t('warehouse.chinese_trade_name')}}: {{item?.customsCnName}}
                            </a-col>
                            <a-col :span="8">
                              {{$t('warehouse.english_trade_name')}}: {{item?.customsEnName}}
                            </a-col>
                            <a-col :span="4">
                              {{$t('warehouse.declare_price')}}: {{ $filters.formatCurrencyAmount(item?.currencyCode, item?.declarePrice, false) }}
                            </a-col>
                            <a-col :span="3">
                              {{$t('warehouse.customs_no')}}: {{item?.hsCode}}
                            </a-col>
                          </a-row>
                        </a-col>
                      </template>
                    </a-row>
                  </div>
                </template>

                <a-button type="link">{{$t('public.more')}}</a-button>
              </a-popover>
            </template>
            <template #isNormal="{ record }">
              <a-popover placement="left"
                         v-if="!record.isNormal">
                <template #content>
                  <div style="max-width:400px">

                    <a-row :gutter="[0,8]">
                      <a-col>
                        <strong>{{$t('warehouse.data_error')}}</strong>
                      </a-col>
                      <a-col :span="24" 
                        v-for="(errObj,index) in record?.errObjs"
                                           :key="index"
                      >
                      <a-row>
                        <a-col>
                      {{index+1}}.
                        </a-col>
                        <a-col>
                            {{$t(getErrorMsgKey(errObj.errCode))}}
                            <span v-if="errObj?.fields&&errObj?.fields?.length>0">
                              (
                                <span v-for="(field,i) in errObj?.fields"
                                      :key="i">
                                  {{field}}
                                  <span v-if="i!=(errObj?.fields?.length-1)">,</span>
                                </span>
                              ) 
                            </span>
                            <div v-if="errObj.msg">
                              <small class="text-grey">{{errObj.msg}}</small>
                            </div>
                        </a-col>
                      </a-row>
                     
                      </a-col>
                    </a-row>
                  </div>
                </template>

                <div class="text-error">
                  <span>{{$t('warehouse.data_error')}}</span>
                  <span class="ml-1 mr-1">
                    <QuestionCircleOutlined />
                  </span>
                </div>
              </a-popover>
            </template>

            <template #transportCharacteristicsCustom="{ record }">
              <a-row :gutter=[0,3]
                     v-if="record.transportCharacteristics?.length>0">
                <a-col v-for="item in record.transportCharacteristics"
                       :key="item">
                  <a-tag color="default"> {{$t($enumLangkey('transportCharacteristics',item))}}</a-tag>
                </a-col>
              </a-row>
              <span v-else>-</span>
            </template>

          </a-table>

          <a-row type="flex"
                 justify="end"
                 class="mt-2">
            <a-col>
              {{$t('warehouse.when_you_confirm_the_import_only_the_correct_product_data_will_be_imported')}}
            </a-col>
          </a-row>
        </a-spin>
        <template #footer>
          <a-row type="flex"
                 justify="end">
            <a-col>
              <a-button @click="()=>{importModal.listVisible=false;importModal.visible=false}">{{ $t("common.cancel") }}</a-button>
            </a-col>
            <a-col class="ml-3">
              <a-button type="primary"
                        :loading="importModal.loading"
                        :disabled="!importModal.whetherCanImport"
                        @click="handleConfirmImport">{{ $t("common.confirm_the_import") }}</a-button>
            </a-col>
          </a-row>
        </template>
      </a-modal>

    </template>
    <template #contentFooter>
      <a-row type="flex"
             justify="space-around"
             align="middle"
             class="mb-1">
        <a-col>
          <CPager @handlePage="handlePage"
                  :page-data="pageData"></CPager>
        </a-col>
      </a-row>
    </template>
  </Content>
</template>

<script>
import Content from "../components/Content.vue";
import { message, Descriptions, Popover, Popconfirm, Spin, Upload, Row, Col, Table, Input, Select, Button, Tag, Space, Tooltip, Dropdown, Menu, Modal } from "ant-design-vue";
import { toRefs, reactive, onActivated, } from "vue";
import CPager from "../components/CPager.vue";
import {
  getProductList, getDefaultProductSeLable,
  joinDownloadTemplateUrl, parseFile, importProduct,
  deleteProduct,exportInventoryList
} from "../../api/modules/product/index";
import { transportCharacteristics } from "../../enum/enum.json";
import { useRouter } from "vue-router";
import CImage from "../components/CImage.vue"
import { useI18n } from "vue-i18n/index";
import localPrint from "../../utils/localPrint"
import { downloadFile } from "../../utils/downloader";
import { gToKg,dateString } from '../../utils/general';
import { getErrorMsgKey } from '../../api/errorHandlers';
import { maxUploadFileSizeByte } from '../../utils/common';

export default {
  name: "product_list",
  components: {
    Content,
    ARow: Row,
    ACol: Col,
    ATable: Table,
    AInput: Input,
    AInputGroup: Input.Group,
    AInputSearch: Input.Search,
    ASelect: Select,
    AButton: Button,
    ATag: Tag,
    ASelectOption: Select.Option,
    ASpace: Space,
    ADropdown: Dropdown,
    ADropdownButton: Dropdown.Button,
    AMenu: Menu,
    AMenuItem: Menu.Item,
    CImage,
    CPager,
    ATooltip: Tooltip,
    AModal: Modal,
    AUpload: Upload,
    ASpin: Spin,
    APopover: Popover,
    ADescriptions: Descriptions,
    ADescriptionsItem: Descriptions.Item,
    APopconfirm: Popconfirm,

  },
  setup (props, { emit }) {
    const router = useRouter();
    const vueI18n = useI18n({ useScope: "global" });

    //#region columns
    const columns = [
      {
        slots: {
          title: "nameAndNo",
          customRender: "productInfo",
        },
        width: 250,
      },
      {
        title: () => vueI18n.t('warehouse.product_packing_imag'),
        slots: {
          customRender: "packingImgUrl",
        },
        width: 100,
      },
      {
        dataIndex: "seSku",
        title: "SESKU",
        width: 100,
      },
      {
        dataIndex: "encasementSpecification",
        title: () => vueI18n.t('warehouse.encasement_specification'),
        width: 100,
      },
      {
        dataIndex: "encasementAmount",
        title: () => vueI18n.t('warehouse.encasement_count'),
        width: 80,
      },
      {
        dataIndex: "encasementGrossWeight",
        title: () => vueI18n.t('warehouse.encasement_weight'),
        width: 80,
      },
      {
        dataIndex: "packagingSize",
        slots: {
          title: "packagingSize",
        },
        width: 100,
      },
      {
        dataIndex: "productGrossWeight",
        title: () => vueI18n.t('warehouse.product_gross_weight'),
        width: 80,
      },
      {
        dataIndex: "transportCharacteristics",
        title: () => vueI18n.t('warehouse.transport_characteristics'),
        slots: {
          customRender: "transportCharacteristicsCustom",
        },
        width: 200,
      },
      {
        dataIndex: "creationTime",
        title: () => vueI18n.t('warehouse.creation_time'),
        slots: {
          customRender: "creationTimeCustom",
        },
        width: 100,
      },
      {
        dataIndex: "operate",
        fixed: "right",
        align: "center",
        slots: {
          customRender: "operate",
        },
        width: 120,
      },
    ];
    //#endregion
    const state = reactive({
      transportCharacteristics: transportCharacteristics,
    })

    const data = reactive({
      selectSearchType: "productNo",
      inputSearchValue: "",
      productListLoading: false,
      inputSearchProductLoading: false,
      productList: [],
      pageData: {
        currentIndex: 1,
        skipCount: 0,
        totalCount: 0,
        maxResultCount: 10
      },
    });


    const handleInputSearch = async () => {
      let searchData = {
        "skipCount": data.pageData.skipCount,
        "maxResultCount": data.pageData.maxResultCount,
        [data.selectSearchType]: data.inputSearchValue,
      };
      data.productListLoading = true;
      data.inputSearchProductLoading = true;
      let res = await getProductList(searchData);
      let { items, totalCount } = res.result;
      data.productList = items.map(x => {
        return {
          key: x.id,
          imgSrc: x.imgUrl,
          previewImage: x.imgUrl,
          packingImgUrl: x.packingImgUrl,
          productName: x.productName,
          productNo: x.productNo,
          seSku: x.seSku,
          encasementSpecification: (x.containerLength && x.containerWidth && x.containerHeight) ? (x.containerLength + "x" + x.containerWidth + "x" + x.containerHeight + "cm") : "-",
          encasementAmount: x.containerCount,
          encasementGrossWeight: gToKg(x.containerWeight) + "kg",
          packagingSize: (x.packingLength && x.packingWidth && x.packingHeight) ? (x.packingLength + "x" + x.packingWidth + "x" + x.packingHeight + "cm") : "-",
          productGrossWeight: gToKg(x.packingWeight) + "kg",
          transportCharacteristics: x.transportCharacteristics,
          creationTime: x.creationTime,
        }
      })
      //data.productList = data.productList.concat(data.productList); 
      data.pageData.totalCount = parseInt(totalCount);
      data.productListLoading = false;
      data.inputSearchProductLoading = false;

      if (data.pageData.skipCount > 0 && data.pageData.skipCount >= data.pageData.totalCount) {
        data.pageData.skipCount = 0;
        data.pageData.currentIndex = 1;
        handleInputSearch();
      }
    };

    const handlePage = async (pageData) => {
      // data.pageData = pageData;
      data.pageData.skipCount = pageData.skipCount;
      data.pageData.maxResultCount = pageData.maxResultCount;
      handleInputSearch();
    }

    const handleEdit = async (productId) => {
      router.push({ path: "/product/edit/" + productId });
    };

    const handleCreateProduct = async (id) => {
      if (id) {
        router.push({ path: "/product/create/" + id });
      } else {
        router.push({ path: "/product/create" });
      }
    };

    const handlePrintOrDownload = async (produtcId, menukey) => {
      getDefaultProductSeLable(produtcId).then(({ result }) => {
        if (result && result.imgUrl) {
          if (menukey == 2) {
            localPrint(result.imgUrl).catch((error) => { })
          } else {
            downloadFile(result.imgUrl, result.code);
          }
        }
      }).catch(() => {

      })
    };


    const handleClickOperateMenu = (e) => {
      let { key, item } = e;
      if (key == 'trigger') {
        return
      }
      let { record } = item
      const eventObj = {
        1: handleCreateProduct,
        2: handlePrintOrDownload,
        3: handlePrintOrDownload,
      }
      eventObj[key](record.key, key)
    }


    //-----------------------导入  begin------------------

    const importColumns = [
      {
        slots: {
          title: "nameAndNo",
          customRender: "productInfo",
        },
        width: 350,
      },
      {
        title: () => vueI18n.t('warehouse.product_packing_imag'),
        slots: {
          customRender: "packingImgUrl",
        },
        width: 100,
      },
      {
        dataIndex: "encasementSpecification",
        title: () => vueI18n.t('warehouse.encasement_specification'),
        width: 100,
      },
      {
        dataIndex: "encasementAmount",
        title: () => vueI18n.t('warehouse.encasement_count'),
        width: 80,
      },
      {
        dataIndex: "encasementGrossWeight",
        title: () => vueI18n.t('warehouse.encasement_weight'),
        width: 80,
      },
      {
        dataIndex: "packagingSize",
        slots: {
          title: "packagingSize",
        },
        width: 100,
      },
      {
        dataIndex: "productGrossWeight",
        title: () => vueI18n.t('warehouse.product_gross_weight'),
        width: 80,
      },
      {
        dataIndex: "transportCharacteristics",
        title: () => vueI18n.t('warehouse.transport_characteristics'),
        slots: {
          customRender: "transportCharacteristicsCustom",
        },
        width: 200,
      },
      {
        dataIndex: "operate",
        fixed: "right",
        align: "center",
        slots: {
          customRender: "operate",
        },
        width: 80,
      },
      {
        fixed: "right",
        align: "center",
        slots: {
          customRender: "isNormal",
        },
        width: 120,
      },
    ];


    const importModal = reactive({
      visible: false,
      loading: false,
      list: [],
      listVisible: false,
      parse: null,
      whetherCanImport: false
    })

    const hanldeShowImportModal = () => {
      importModal.visible = true;
      importModal.loading = false;
      importModal.list = [];
      importModal.listVisible = false;
      importModal.parse = null;
      importModal.whetherCanImport = false;
    }

    const hanldeDownloadTemplateFile = () => {
      importModal.loading = true
      let url = joinDownloadTemplateUrl();
      downloadFile(url, "Product template file.xlsx");
      importModal.loading = false
    }


    const handleParseFile = (file) => {
      if (file?.file?.size > maxUploadFileSizeByte() ) {
        message.error(vueI18n.t('common.please_upload_file_with_size_less_than_50MB'));
        return
      }
      importModal.loading = true
      let parameter = new FormData();
      parameter.append("file", file.file);
      parseFile(parameter).then((res) => {
        if (res.result) {
          importModal.parse = res.result;

          importModal.list = res.result.map(x => {
            if (x.isNormal) {
              importModal.whetherCanImport = x.isNormal;
            }
            return {
              key: x.id,
              imgSrc: x.imgUrl,
              previewImage: x.imgUrl,
              packingImgUrl: x.packingImgUrl,
              productName: x.productName,
              productNo: x.productNo,
              seSku: x.seSku,
              encasementSpecification: (x.containerLength && x.containerWidth && x.containerHeight) ? (x.containerLength + "x" + x.containerWidth + "x" + x.containerHeight + "cm") : "-",
              encasementAmount: x.containerCount,
              encasementGrossWeight: gToKg(x.containerWeight) ? gToKg(x.containerWeight) + "kg" : '-',
              packagingSize: (x.packingLength && x.packingWidth && x.packingHeight) ? (x.packingLength + "x" + x.packingWidth + "x" + x.packingHeight + "cm") : "-",
              productGrossWeight: gToKg(x.packingWeight) ? gToKg(x.packingWeight) + "kg" : '-',
              transportCharacteristics: x.transportCharacteristics,
              creationTime: x.creationTime,
              isNormal: x.isNormal,
              errObjs: x?.errObjs,
              more: x
            }
          });
          importModal.listVisible = true;
          importModal.visible = false;
        }
        importModal.loading = false
      }).catch(() => {
        importModal.loading = false
      });
    }

    const handleConfirmImport = () => {
      importModal.loading = true;
      let importData = importModal.parse?.filter((x) => x.isNormal);
      if (!importData || importData.length == 0) {
        return
      }
      importProduct(importData).then(() => {
        importModal.listVisible = false;
        importModal.visible = false;
        importModal.loading = false;
        handleInputSearch();
      }).catch(() => {
        importModal.loading = false;
      })
    }


    const handleDeleteProduct = (record) => {
      deleteProduct(record.key).then(() => {
        message.success(vueI18n.t("common.delete_success"));
        handleInputSearch();
      }).catch(() => {
      })
    }

    //-----------------------导入  end------------------

    const handleDownloadExcel = () => {
       data.inputSearchProductLoading = true
      let url = exportInventoryList()
      const postData = {
        [data.selectSearchType]: data.inputSearchValue,
      };
      downloadFile(url, `products_${dateString()}.csv`, "POST", postData)
        .then(() => {
          data.inputSearchProductLoading = false
        })
        .catch(() => {
          data.inputSearchProductLoading = false
        })
    }

    onActivated(async () => {
      if (data.productListLoading) {
        return;
      }
      handleInputSearch();
    });

    return {
      ...toRefs(state),
      ...toRefs(data),
      columns,
      importColumns,
      handleEdit,
      handlePage,
      handleInputSearch,
      handleClickOperateMenu,
      handleCreateProduct,
      importModal,
      hanldeShowImportModal,
      hanldeDownloadTemplateFile,
      handleParseFile,
      handleConfirmImport,
      gToKg,
      getErrorMsgKey,
      handleDeleteProduct,
      handleDownloadExcel
    };
  },
};
</script>
<style lang="less" scoped>
.inputSearchValue {
  width: 220px;
}
</style>
